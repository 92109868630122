import React, { forwardRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import WAScreen from './WAscreen';
import WAActionBar from '../WAActionBar';

const MobileDeviceFrame = forwardRef(({ className, data, onClickEdit, showActions }, ref) => {
  const [formData, setFormData] = useState();

  useEffect(() => {
    ref.current = setFormData;
  }, []);

  return (
    <div className={classNames('mobile-device-frame', className)} style={{ marginTop: showActions ? -53 : 0}}>
      <WAActionBar onClickEdit={onClickEdit} showActions={showActions} />
      <div className="device-frame">
        <WAScreen data={data || formData} />
      </div>
      <div className="mobile-device-note">
        <p>
          <strong>Note :</strong> This is an illustrative preview. Actual renditions on device may vary per Meta UI for WhatsApp. Any dynamic placeholders have been replaced with sample values to show a more realistic preview.
        </p>
      </div>
    </div>
  );
});

export default MobileDeviceFrame;
