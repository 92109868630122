// Dashboard Reducer
import {
  GET_CAMPAIGN,
  ASYNC_IN_PROGRESS,
  UDPATE_CAMPAIGN_DETAILS,
  SERVICE_ERROR_CAMPAIGN_DETAILS,
  SERVICE_ERROR_CAMPAIGN_SUBMISSION,
  PUSH_STATE,
  RESET_FORM_SUBMISSION,
  UPDATE_ADDITIONAL_OPTINS,
  SAVE_CAMPAIGN_DATA,
  GET_CAMPAIGN_COOKIE_PRIVACY_URL
} from '../actionTypes';

const initialState = {
  name: '',
  type: '',
  isAlwaysOn: null,
  additionalConsentFlag: null,
  additionalOptinList: [{ text: '', id: '', frequency: '' }],
  addConsentOptinFlag: null,
  dpiaConfirmationFlag: null,
  description: null,
  cookiePolicyUrl: null,
  privacyPolicyUrl: null,
  tncUrl: null,
  ageOfConsent: '16',
  brandUrl: null,
  doubleOptinReq: null,
  disclaimerTextReq: null,
  disclaimerText: null,
  emailConfirmationFlag: null, //4827
  requestersEmail: null,
  emailSenderName: null,
  replyToEmailName: null,
  replyToEmailAdderess: null,
  smsChannel: null,
  startDate: null,
  endDate: null,
  dpiaReferenceNo: null,
  serviceError: false,
  serviceErrorStatus: null,
  serviceErrorMessage: null,
  pushstate: false,
  campaignLoaded: false,
  emailChannel: true, //5022
  smsChannel: false,
  isWhitelistedForGigya: null,
  campaignType: null,
  incentiveType: null,
  brandname: null,
  dataSaved: false,
  country: false,
  language: false,
  cookieUrl: false,
  privacyUrl: false,
  privacyUrlFieldUpdated: false,
  cookieUrlFieldUpdated: false,
  isCampaignEdited: false,
  isTrackingRequired: null,
  isCustomTrackingId: null,
  customTrackingId: null,
  websiteType: '',
  showSMSChannel: null,
  showEmailChannel: null,
  autogenerate: true,
  samples: [],
  promotigoFormId: '',
  isRadioButtonReq: false,
  instantWinLose: false,
  redirectionUrl: null,
  displayProductSamplesAs: '',
  iConfirmQnA: false,
  isClone: false
};
export default function(state = initialState, action) {
  switch (action.type) {
    case ASYNC_IN_PROGRESS: {
      return state;
    }
    case UDPATE_CAMPAIGN_DETAILS: {
      let campaignDetails = action.campaignDetails;

      if (
        action.campaignDetails &&
        'privacyPolicyUrl' in action.campaignDetails
      ) {
        return {
          ...state,
          ...campaignDetails,
          ...{ privacyUrlFieldUpdated: true }
        };
      } else if (
        action.campaignDetails &&
        'cookiePolicyUrl' in action.campaignDetails
      ) {
        return {
          ...state,
          ...campaignDetails,
          ...{ cookieUrlFieldUpdated: true }
        };
      } else {
        return { ...state, ...campaignDetails };
      }
    }
    case UPDATE_ADDITIONAL_OPTINS: {
      let additionalOptinList = {
        additionalOptinList: [...action.additionalOptinList]
      };

      return { ...state, ...additionalOptinList };
    }
    case SERVICE_ERROR_CAMPAIGN_DETAILS:
    case SERVICE_ERROR_CAMPAIGN_SUBMISSION: {
      let serviceError = {
        serviceError: true,
        serviceErrorStatus:
          (action.error.response && action.error.response.status) || 401,
        serviceErrorMessage:
          action.error.response && action.error.response.data.message
      };
      return { ...state, ...serviceError };
    }
    case PUSH_STATE: {
      let pushstate = { pushstate: action.pushstate };
      return { ...state, ...pushstate };
    }
    case RESET_FORM_SUBMISSION: {
      return { ...state, ...{ pushstate: false } };
    }
    case GET_CAMPAIGN: {
      let alwaysOn =
        !action.campaign.incentiveType ||
        (action.campaign.incentiveType &&
          action.campaign.incentiveType !== 'Competitions' &&
          action.campaign.campaignDetail)
          ? action.campaign.campaignDetail &&
            action.campaign.campaignDetail.isAlwaysOn
            ? action.campaign.campaignDetail.isAlwaysOn
            : null
          : null;
      let campaign = {
        name: action.campaign.name,
        type: action.campaign.type,
        campaignType: action.campaign.campaignType,
        incentiveType: action.campaign.incentiveType,
        isAlwaysOn: alwaysOn,
        additionalConsentFlag:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.additionalConsentFlag,
        additionalOptinList: (action.campaign.campaignDetail &&
          action.campaign.campaignDetail.additionalOptinList.length &&
          action.campaign.campaignDetail.additionalOptinList) || [
          { text: '', id: '', frequency: '' }
        ],
        addConsentOptinFlag:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.addConsentOptinFlag,
        dpiaConfirmationFlag:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.dpiaConfirmationFlag,
        description:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.description,
        cookiePolicyUrl:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.cookiePolicyUrl,
        privacyPolicyUrl:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.privacyPolicyUrl,
        tncUrl:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.tncUrl,
        ageOfConsent:
          (action.campaign.campaignDetail &&
            action.campaign.campaignDetail.ageOfConsent) ||
          '16',
        brandUrl:
          (action.campaign.campaignDetail &&
            action.campaign.campaignDetail.brandUrl) ||
          '',
        doubleOptinReq:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.doubleOptinReq,
        disclaimerTextReq:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.disclaimerTextReq,
        disclaimerText:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.disclaimerText,
        emailConfirmationFlag:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.emailConfirmationFlag, //4827
        requestersEmail:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.requestersEmail,
        emailSenderName: action.campaign.brand && action.campaign.brand.name,
        replyToEmailName:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.replyToEmailName,
        replyToEmailAdderess:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.replyToEmailAdderess,
        smsChannel:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.smsChannel,
        startDate:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.startDate,
        endDate:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.endDate,
        endDateTime:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.endDate,
        dpiaReferenceNo:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.dpiaReferenceNo,
        emailChannel:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.emailChannel, //5022
        smsChannel:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.smsChannel,
        isWhitelistedForGigya:
          action.campaign && action.campaign.isWhitelistedForGigya,
        brandname:
          action.campaign &&
          action.campaign.brand &&
          action.campaign.brand.name,
        serviceError: false,
        serviceErrorStatus: null,
        serviceErrorMessage: null,
        pushstate: false,
        country:
          action.campaign &&
          action.campaign.country &&
          action.campaign.country.code,
        language:
          action.campaign &&
          action.campaign.language &&
          action.campaign.language.code,
        campaignLoaded: true,
        isCampaignEdited: action.campaign && action.campaign.isEdited,
        isTrackingRequired:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.isTrackingRequired,
        isCustomTrackingId:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.isCustomTrackingId,
        customTrackingId:
          (action.campaign.campaignDetail &&
            action.campaign.campaignDetail.customTrackingId) ||
          null,
        websiteType:
          (action.campaign &&
            action.campaign.websiteType &&
            action.campaign.websiteType.name) ||
          '',
        showEmailChannel: action.campaign.showEmailChannel,
        showSMSChannel: action.campaign.showSMSChannel,
        autogenerate: action.campaign && action.campaign.autogenerate,
        samples:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.samples,
        promotigoFormId:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.promotigoFormId,
        isRadioButtonReq:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.isRadioButtonReq,
        instantWinLose:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.instantWinLose,
        redirectionUrl:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.redirectionUrl,
        displayProductSamplesAs:
          (action.campaign.campaignDetail &&
            action.campaign.campaignDetail.displayProductSamplesAs) ||
          '',
        isPlatformAgnostic: action.campaign.isPlatformAgnostic
          ? action.campaign.isPlatformAgnostic
          : false,
        autogenerate: action.campaign.autogenerate
          ? action.campaign.autogenerate
          : false,
        iConfirmQnA:
          action.campaign &&
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.iConfirmQnA,
        campaignProductionUrl:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.campaignProductionUrl,
        campaignNonProdUrls:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.campaignNonProdUrls,
        offlineInjection:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.offlineInjection,
        customInjection:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.customInjection,
        isMasterData: action.campaign && action.campaign.isMasterData,
        incentiveItPartner:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.incentiveItPartner,
        vendorPartner:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.vendorPartner,
        isMigrationRequest:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.isMigrationRequest,
        masterDataInjection:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.masterDataInjection,
        masterDataCollectionPartner:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.masterDataCollectionPartner,
        masterDataOtherCollectionPartner:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.masterDataOtherCollectionPartner
      };
      return { ...state, ...campaign };
    }
    case SAVE_CAMPAIGN_DATA: {
      let savedata = { dataSaved: action.dataSaved };
      return { ...state, ...savedata };
    }
    case GET_CAMPAIGN_COOKIE_PRIVACY_URL: {
      let url = {
        cookieUrl: action.urlData.cookieUrl,
        privacyUrl: action.urlData.privacyUrl
      };
      return { ...state, ...url };
    }
    default:
      return state;
  }
}
