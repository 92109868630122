import React, { useState, forwardRef } from 'react';
import GenerateImagePathModal from './GenerateImagePathModal';
import { FieldError } from 'components/atoms/FieldError';
const getTransformedImageData = info => {
  if (info.imageSelector) {
    return {
      id: info.imageSelector.assetID,
      extn: info.imageSelector.fileType.value,
      query: {
        width: info.imageSelector.resizeWidth,
        height: info.imageSelector.resizeHeight
      }
    };
  }
  return undefined;
};

const GenerateImageInput = forwardRef(
  (
    {
      onChange,
      value,
      name,
      errors,
      defaultValue,
      setValue,
      fieldPrefix,
      getValues
    },
    ref
  ) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [imageData, setImageData] = useState(defaultValue?.imageSelector);

    return (
      <div className="pt-2 ml-22 pt-0 cw-form-nested" aria-live="polite">
        <button
          type="button"
          className="btn btn-primary mt-2"
          onClick={() => setIsModalOpen(true)}
        >
          Specify Image
        </button>
        <GenerateImagePathModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          handleSubmit={(url, frm, data) => {
            onChange(url);
            if (data.imageSelector && fieldPrefix) {
              const imageDetails = getTransformedImageData(data);
              setImageData(imageDetails);
              setValue(`${fieldPrefix}.imageSelector`, imageDetails);
            }

            setIsModalOpen(false);
          }}
          handleCloseForm={() => {
            setIsModalOpen(false);
          }}
          type={'src'}
          currentImage={imageData}
          isWABriefing={true}
        />

        <p className="pt-30">
          {value ? (
            <a target={'_blank'} href={value}>
              {value}
            </a>
          ) : null}
        </p>

        <FieldError name={name} errors={errors} />
      </div>
    );
  }
);

export default GenerateImageInput;
