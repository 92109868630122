import React from 'react';
import { get, isEmpty } from 'lodash';
import classNames from 'classnames';

const buttonTypeIconMap = {
  'Website URL': 'zmdi zmdi-open-in-new',
  'Phone number': 'zmdi zmdi-phone',
  'Phone number button': 'zmdi zmdi-phone', // nested button
  'Unsubscribe quick reply': 'zmdi zmdi-mail-reply',
  'Quick reply - Unsubscribe': 'zmdi zmdi-mail-reply',
  'Quick reply - Others': 'zmdi zmdi-mail-reply',
  'Copy code': 'zmdi zmdi-copy',
  'Copy coupon code': 'zmdi zmdi-copy',
  'Copy code button': 'zmdi zmdi-copy'
};

const WAChatButtons = ({ data, isCarousel }) => {
  const isButtonsConfigured = !isEmpty(data);
  /**
   * collectNestedArrayItems returns a flat array of objects
   * from a nested object to make it compatible with the WAButtons
   * @param {*} sourceArray
   * @param {*} nestedKey
   * @returns
   */
  const collectNestedArrayItems = (sourceArray, nestedKey) => {
    let collectedItems = [];
    sourceArray &&
      sourceArray.forEach(item => {
        if (item[nestedKey] && Array.isArray(item[nestedKey])) {
          collectedItems = collectedItems.concat(item[nestedKey]);
        }
      });
    return collectedItems;
  };
  data = isCarousel ? data : collectNestedArrayItems(data, 'nestedArray');

  const getButtonList = () => {
    let list = [];
    for (let i = 0; i < 3; i++) {
      if (data[i]) {
        const buttonType = isCarousel ? data[i]?.type?.value : data[i]?.type;
        if (i === 2) {
          if (data.length > 3) {
            list.push(
              <div className="wa-chat-btn" key={`chat_btn_${i}`}>
                <div className="wa-chat-btn-icon">
                  <i className="zmdi zmdi-menu"></i>
                </div>
                <div className="wa-chat-btn-text">
                  <span>See all options</span>
                </div>
              </div>
            );
          } else {
            list.push(
              <div className="wa-chat-btn" key={`chat_btn_${i}`}>
                <div className="wa-chat-btn-icon">
                  <i className={buttonTypeIconMap[buttonType]}></i>
                </div>
                <div className="wa-chat-btn-text">
                  <span>{data[i].buttonName}</span>
                </div>
              </div>
            );
          }
        } else {
          list.push(
            <div className="wa-chat-btn" key={`chat_btn_${i}`}>
              <div className="wa-chat-btn-icon">
                <i className={buttonTypeIconMap[buttonType]}></i>
              </div>
              <div className="wa-chat-btn-text">
                <span>{data[i].buttonName}</span>
              </div>
            </div>
          );
        }
      }
    }
    return list;
  };

  return isButtonsConfigured ? (
    <div
      className={classNames('wa-chat-btn-container', {
        'wa-chat-btn-mt-0': isCarousel
      })}
    >
      {getButtonList()}
    </div>
  ) : null;
};

export default WAChatButtons;
