import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { FormBuilder } from 'components/organisms/FormBuilder';
import ProgressBar from 'components/molecules/ProgressBar';
import Loader from 'components/Loader';
import { CustomButtons } from 'pages/EmailCampaignAudience';
import formSchema from './WACampaignDesignSchema';
import { updateWAComms } from '../../actions/wabriefings/waComms';
import Modal from 'react-modal';
import constants from 'constants/config';
import { getWAcommsByUid } from 'actions/wabriefings/waComms';
import MobileDeviceFrame from 'components/organisms/MobileDeviceFrame';
import { isEmpty, get } from 'lodash';

const WACampaignDesign = props => {
  const [isEdit, setEdit] = useState(false);
  const waCampaignDetails = useSelector(state => state.waBriefings);
  const previewRef = useRef();

  const { authorizedUserData } = useSelector(state => state.authorized);

  const dispatch = useDispatch();
  useEffect(() => {
    const pathUid = props.match.params.id;
    if (pathUid) {
      setEdit(true);
      dispatch(getWAcommsByUid(pathUid));
    }
    const rowDomElement = window.document.getElementById("main-row-box");
    if(rowDomElement){
      rowDomElement.classList.add("wa-design-bg")
    }
    return () => {
      const rowDomElement = window.document.getElementById("main-row-box");
      if(rowDomElement){
        rowDomElement.classList.remove("wa-design-bg")
      }
    } 
  }, []);

  //console.log('Design load page::', compProps, ' state - ',useSelector(state => state.waBriefings));

  const handleFormValueChange = (givenValue, defaultValue) => {
    const previewData = {};
    if (!isEmpty(givenValue)) {
      previewData.waHeader = givenValue[0];
      previewData.waBodyText = givenValue[1];
      previewData.waFooterText = givenValue[2];
      previewData.waButtons = givenValue[3];
      previewData.waOffer = givenValue[4];
      previewData.carousel = givenValue[5];
    }
    if (get(waCommsData, 'brand')) {
      previewData.brand = get(waCommsData, 'brand');
    }
    if (get(waCommsData, 'deliverySchedule')) {
      previewData.deliverySchedule = get(waCommsData, 'deliverySchedule');
    }
    console.log('previewData: ', previewData);
    if (previewRef.current) {
      previewRef.current(previewData);
    }
  };

  if (!waCampaignDetails) return null;
  const { isLoading, waCommsData } = waCampaignDetails;
  const { waDesign, status } = waCommsData;
  console.log('waCommsData', waCommsData);
  const compProps = {
    history: props.history,
    match: props.match,
    defaultValues: waCommsData,
    status: status,
    onFormValueChange: handleFormValueChange
  };

  console.log('Design load page::', compProps, waCampaignDetails);

  return (
    <>
      <div className="col-sm-12 skypink email-campaign-brand-selection wa-design-bg">
        <div className="cw-section">
          <ProgressBar
            customClass={'cw-email-progress-custom'}
            pageNo={constants.WA_JOURNEY_PAGE_NUMBERS.DESIGN}
            totalPage={constants.WA_PROGRESS_BAR_TOT_PAGES}
          />

          <div className="cw-section--title mb-30">
            <h2 className="cw-heading--secondary cw-email-heading">
              Design WhatsApp
            </h2>
          </div>

          <div className="row">
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <div className="col-md-8 wa-design">
                  <FormBuilder
                    schema={formSchema(dispatch, compProps, {})}
                    user={authorizedUserData}
                    isEdit={isEdit}
                    customButtonsComp={CustomButtons}
                  />
                </div>
                <div className="col-md-4">
                  <MobileDeviceFrame ref={previewRef} className="pt-20" />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default WACampaignDesign;
