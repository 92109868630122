import React, { useEffect, useState, useCallback } from 'react';
import { Input } from '../../atoms/Input';
import {
  useFieldArray,
  useWatch,
  Controller,
  useController
} from 'react-hook-form';
import { FieldError } from '../../atoms/FieldError';
import { get, set, cloneDeep } from 'lodash';
import Select from 'react-select';
import { FormReactSelect } from '../../molecules/FormReactSelect';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import arrayMove from 'array-move';
import classNames from 'classnames';
import WAButtonGuidelines from 'components/molecules/WAButtonGuidelines';

export const WAButtons = ({
  name,
  type,
  rules,
  register,
  label,
  subLabel,
  errors,
  control,
  defaultValue,
  maxRow,
  isMulti,
  waDesign,
  ...props
}) => {
  const { fields, append, move, remove, swap } = useFieldArray({
    control,
    name: name,
    defaultValue: defaultValue
  });
  const childrenRef = React.useRef({});
  const watchFieldArray = useWatch({ control, name: name });
  const controlledFields = fields.map((field, index) => {
    return { ...field, ...watchFieldArray[index] };
  });
  const [
    isButtonGuidelinesModalOpen,
    setIsButtonGuidelinesModalOpen
  ] = useState(false);

  // to hide add button
  const hideAddButton = !(
    controlledFields.length >=
    (isMulti && maxRow ? maxRow : props.waCouponType ? 5 : 4)
  );

  // to add a row
  const handleAppend = () => {
    const maxRows = isMulti && maxRow ? maxRow : props.waCouponType ? 5 : 4;
    if (controlledFields.length < maxRows) {
      append({
        type: isMulti
          ? {
              value: '',
              label: ''
            }
          : '',
        buttonName: '',
        buttonValue: ''
      });
      return true;
    }
  };

  // -- in use --
  const ButtonTypeOptions = [
    { value: 'Website URL', label: 'Website URL (max 2)' }
  ];
  if (props.waCouponType) {
    ButtonTypeOptions.push({
      value: 'Copy coupon code',
      label: 'Copy coupon code (max 1)'
    });
  }
  ButtonTypeOptions.push({
    value: 'Phone number',
    label: 'Phone number (max 1)'
  });
  ButtonTypeOptions.push({
    value: 'Unsubscribe quick reply',
    label: 'Unsubscribe quick reply (max 1)'
  });

  const [buttonOptions, setbuttonOptions] = useState(ButtonTypeOptions);

  //- disable option conditionally-----
  const disableOptions = option => {
    if (isMulti) {
      return (
        watchFieldArray?.filter(opt => {
          return opt.type.value === option.value;
        }).length >= 1
      );
    } else {
      if (option.value === 'Website URL') {
        return (
          watchFieldArray?.filter(opt => {
            return opt.type.value === 'Website URL';
          }).length >= 2
        );
      } else {
        return (
          watchFieldArray?.filter(opt => {
            return opt.type.value === option.value;
          }).length >= 1
        );
      }
    }
  };
  //--------

  useEffect(() => {
    // sets the DB value
    props.setValue(name, defaultValue);
  }, [defaultValue]);

  const optionHandler = useCallback(value => {
    const optionToRemove = value;
    const newArray = buttonOptions.filter(
      obj =>
        obj.value !== optionToRemove.value || obj.label !== optionToRemove.label
    );
    setbuttonOptions(newArray);
  });

  /******** added bug WTG-15112 *******/
  const messageType = useWatch({
    control,
    name: `${'carousel'}.messageType`,
    defaultValue: get(waDesign, `${'carousel'}.messageType`)
  });
  if (messageType && messageType.value === 'carousel' && !isMulti) return null;
  /********bug WTG-15112 ******/

  // - Flag to show a common error for row
  const showCommonErrors = props.showCommonErrors;
  // - Delete button
  const ButtonRemove = ({ index }) => {
    return (
      <div className="form-group row-action">
        <button
          type="button"
          className="btn-circle btn-remove ml-10 mt-30"
          onClick={() => remove(index)}
        >
          <i className="fas fa-times"></i>
        </button>
      </div>
    );
  };
  const handleGuidelinesModal = e => {
    e.preventDefault();
    setIsButtonGuidelinesModalOpen(true);
  };
  const ButtonGuidelinesLink = () => {
    return (
      <button className="btn btn-primary-link" onClick={handleGuidelinesModal}>
        See guidelines
      </button>
    );
  };

  //--------- Drag N Drop using react-beautiful-dnd -------------------------
  const reorder = result => {
    const { source, destination, type } = result;
    if (!destination) {
      return;
    }
    const sourceIndex = source.index;
    const destIndex = destination.index;

    if (type === 'parentContainer') {
      move(sourceIndex, destIndex);
    } else if (type === 'childContainer' && source.droppableId) {
      const reorderChild = childrenRef.current[source.droppableId];
      if (reorderChild) {
        reorderChild(sourceIndex, destIndex);
      }
    }
  };

  return (
    <div className={classNames(props.formGroupWrapperClass)}>
      {label && (
        <label htmlFor={props.id}>
          {label}
          {subLabel && (
            <div className="description mb-20">
              {subLabel} {<ButtonGuidelinesLink />}
            </div>
          )}
        </label>
      )}
      <div className="wa-field-array">
        <DragDropContext onDragEnd={reorder}>
          <Droppable droppableId="parent" type="parentContainer">
            {provided => (
              <ul
                className="container"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {fields.map((item, index) => {
                  const errorMessages = get(errors, `${name}.${index}`);
                  const hasError = !!(errors && errorMessages);
                  return (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {provided => (
                        <li
                          key={item.id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={
                            hasError
                              ? 'd-flex justify-content-start align-items-center mt-10 wa-buttons-row row errors'
                              : 'd-flex justify-content-start align-items-center mt-10 wa-buttons-row row'
                          }
                        >
                          <div
                            className={
                              errorMessages && errorMessages['type']
                                ? 'col-4 form-group error-col'
                                : 'col-4 form-group'
                            }
                          >
                            <div
                              className="wa-buttons-row__drag-handle"
                              {...provided.dragHandleProps}
                            >
                              <i className="fa fa-grip-vertical"></i>
                            </div>
                            <label>Button type</label>
                            <Select
                              options={buttonOptions.map(option => ({
                                ...option,
                                isDisabled: disableOptions(option)
                              }))}
                              onChange={selectedOption => {
                                let defaultVal = '';
                                if (
                                  selectedOption.value === 'Copy coupon code'
                                ) {
                                  defaultVal = props.waCouponCode;
                                }
                                props.setValue(
                                  `${name}.${index}.type`,
                                  selectedOption
                                );
                                props.setValue(
                                  `${name}.${index}.buttonValue`,
                                  defaultVal
                                ); // reset buttonValue on type change
                              }}
                              placeholder="Please select"
                              defaultValue={item.type}
                              className={
                                errorMessages && errorMessages['type']
                                  ? 'cw-error-focus'
                                  : ''
                              }
                            />
                            {!showCommonErrors && (
                              <FieldError
                                errors={errors}
                                name={`${name}.${index}.type`}
                              />
                            )}
                          </div>
                          <div
                            className={
                              errorMessages && errorMessages['buttonName']
                                ? 'col-4 form-group error-col'
                                : 'col-4 form-group'
                            }
                          >
                            <label>Button Name</label>
                            <input
                              type="text"
                              name={`${name}.${index}.buttonName`}
                              defaultValue={item.type}
                              {...register(`${name}.${index}.buttonName`)}
                              className={
                                errorMessages && errorMessages['buttonName']
                                  ? 'form-control cw-error-focus'
                                  : 'form-control'
                              }
                              maxLength={25}
                            />
                          </div>
                          <div
                            className={
                              errorMessages && errorMessages['buttonValue']
                                ? 'col-4 form-group col-with-button error-col'
                                : 'col-4 form-group col-with-button'
                            }
                          >
                            <Inputvalue
                              control={control}
                              watchValue={`${name}.${index}.type`}
                              name={`${name}`}
                              index={index}
                              register={register}
                              onChangeHandler={optionHandler}
                              className={
                                errorMessages && errorMessages['buttonValue']
                                  ? 'form-control cw-error-focus'
                                  : 'form-control'
                              }
                            />
                            {isMulti ? (
                              fields && fields.length > 1? (
                                <ButtonRemove index={index} />
                              ) : null
                            ) : (
                              <ButtonRemove index={index} />
                            )}
                          </div>
                          {showCommonErrors && hasError && (
                            <div className="row">
                              <div
                                className={'col-12 show-common-error pb-2 ml-2'}
                              >
                                <span className={'cw-error'}>
                                  <i className="fas fa-exclamation-triangle mr-10"></i>
                                  Please specify all button properties
                                </span>
                              </div>
                            </div>
                          )}
                        </li>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
          {hideAddButton && (
            <div className="d-flex align-items-center mt-10 mb-10">
              <div className="col">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => handleAppend()}
                >
                  Add button
                </button>
              </div>
              <div className="col">
                {fields.length === 0 && <p>No buttons have been added</p>}
              </div>
            </div>
          )}
        </DragDropContext>
      </div>
      <WAButtonGuidelines
        isOpen={isButtonGuidelinesModalOpen}
        handleCloseForm={() => setIsButtonGuidelinesModalOpen(false)}
      />
    </div>
  );

  //--------- DND using react-beautiful-dnd -------------------------
};

// TODO: make it external
const Inputvalue = ({
  control,
  watchValue,
  name,
  index,
  register,
  onChangeHandler
}) => {
  const value = useWatch({ control, name: watchValue });
  //onChangeHandler(value)
  switch (value.value) {
    case 'Website URL':
      return (
        <>
          <label htmlFor="">{'Website URL'}</label>
          <input
            className="form-control"
            type="text"
            placeholder="Target URL"
            {...register(`${name}.${index}.buttonValue`)}
            maxLength={25}
          />
        </>
      );
    case 'Copy coupon code':
      return (
        <>
          <label htmlFor="">{'Copy coupon code'}</label>
          <input
            className="form-control"
            type="text"
            placeholder="Coupon Code"
            {...register(`${name}.${index}.buttonValue`)}
            maxLength={25}
          />
        </>
      );
    case 'Phone number':
      return (
        <>
          <label htmlFor="">{'Phone number'}</label>
          <input
            className="form-control"
            type="number"
            placeholder="Phone Number"
            {...register(`${name}.${index}.buttonValue`)}
            maxLength={20}
            form="novalidatedform"
          />
        </>
      );
    case 'Unsubscribe quick reply':
      return (
        <>
          <label htmlFor="">{'Unsubscribe quick reply'}</label>
          <input
            className="form-control"
            type="text"
            placeholder="Unsubscribe quick reply"
            {...register(`${name}.${index}.buttonValue`)}
            maxLength={25}
          />
        </>
      );
    default:
      return null;
  }
};
