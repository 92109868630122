import React from 'react';
import { get } from 'lodash';

const CardDisplay = ({ data, WAHeaderTypeLabel }) => {
  const properties = [];
  const headerType = get(data, 'cardHeader.type');
  properties.push({
    label: 'Card header',
    value: WAHeaderTypeLabel[headerType],
    mustShow: true
  });
  if (headerType === 'headerImage') {
    properties.push({
      label: 'Card header image',
      value: get(data, 'cardHeader.headerImage'),
      mustShow: true
    });
  }

  if (headerType === 'headerVideo') {
    properties.push({
      label: 'Card header video',
      value: get(data, 'cardHeader.headerVideo'),
      mustShow: true
    });
  }
  properties.push({
    label: 'Card body text',
    value: get(data, 'cardBodyText'),
    mustShow: true
  });
  const waDesignButtons = get(data, 'buttons', []);

  if (waDesignButtons.length) {
    waDesignButtons.forEach((item, index) => {
      properties.push({
        label: `Button ${index + 1}`,
        value: `${get(item, 'type.value')} - ${get(item, 'buttonName')} - ${get(
          item,
          'buttonValue'
        )}`,
        mustShow: true
      });
    });
  }
  return (
    <div className="mb-2 wa-carousel-table-wrapper">
      {properties.map(property => (
        <div
          className="email-tabular__row row"
          key={'campaigndetails_' + property.label}
        >
          <div className="col-sm-3">
            <strong className="email-tabular__label wa-carousel-table-label">
              {property.label}
            </strong>
            <span className="email-tabular__subLabel">{property.subLabel}</span>
          </div>
          <div className={`col-sm-9 email-tabular__value`}>
            {property.value}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardDisplay;
