import React, { useState } from 'react';
import Modal from 'react-modal';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import WAButtonGuideline from './WAButtonGuideline';
import { TabPanel } from 'pages/ReportingDashboard/ReportingDashboard';
import EmailCouponIcon from '../../../images/email-coupon.svg';

import constants from 'constants/config';

const WAButtonGuidelines = ({ isOpen, defaultTab, handleCloseForm }) => {
  Modal.setAppElement('#root');
  const [activeTab, setActiveTab] = useState(defaultTab || 0);

  const tabStyle = { fontFamily: 'Open Sans', textTransform: 'none' };

  const handleChange = (e, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        setActiveTab(defaultTab || 0);
        handleCloseForm();
      }}
      className="cw-modal cw-modal--coupon-guidelines wa-button-guidelines"
      contentLabel="Save row form"
      style={{
        overlay: {
          backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
        }
      }}
    >
      <div className="email-overlay-wrapper">
        <span
          className="qna-guideline-close email-overlay-close-modal"
          onClick={() => {
            setActiveTab(defaultTab || 0);
            handleCloseForm();
          }}
        ></span>

        <div className="d-flex mb-20">
          <div className="ml-10 pr-3">
            <div className={'cw-heading--saascampaign send-test-email__title'}>
              WhatsApp message button guidelines
            </div>
          </div>
        </div>

        <div className="overlay-content-wrapper">
          <div className="overlay-content">
            <WAButtonGuideline
              listStyleType="disc"
              listStyleTypeColor="black"
              liClassName="li-item-color"
              noMargin
              isEmailCampaign
              hideCloseButton
              hideHorizontalLine
              data={constants.WA_BUTTONS_GUIDELINES}
              noUlLineBreak
              noSubTitleLineBreak
            />
          </div>

          <div className="bottom-cta">
            <div className="horizontal-line"></div>

            <div className="mt-3">
              <button
                type="submit"
                onClick={() => {
                  setActiveTab(defaultTab || 0);
                  handleCloseForm();
                }}
                className="btn btn-block btn-outline-secondary mb-10 cw-print-hide text-nowrap"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WAButtonGuidelines;
