import constant from '../constants';
import {
  epsilonMarket,
  checkIsCIAEnabled,
  isBrandEmailSmsConsentVisible,
  isWhatsAppConsentEnableForMarket
} from '../selectors/index';
import { cloneDeep, get } from 'lodash';
import moment from 'moment';

const consentTypes = constant.consentTypes;

export const getUnileverConsent = (
  consentType,
  isEpsilonMarket,
  countryCode,
  brandCode,
  campaignConfig
) => {
  if (consentType === consentTypes.unileverEmailConsent) {
    return getUnileverEmailConsent(isEpsilonMarket, countryCode);
  } else if (consentType === consentTypes.unileverSMSConsent) {
    return getUnileverSMSConsent(isEpsilonMarket, countryCode);
  } else if (consentType === consentTypes.unileverEmailOnlyConsent) {
    return getUnileverEmailOnlyConsent(isEpsilonMarket, countryCode);
  } else if (consentType === consentTypes.unileverOnlineOnlyConsent) {
    return getUnileverOnlineAdvertisingOnlyConsent(
      isEpsilonMarket,
      countryCode
    );
  } else if (consentType === consentTypes.unileverSMSOnlyConsent) {
    return getUnileverSMSOnlyConsent(isEpsilonMarket, countryCode);
  } else if (
    consentType === consentTypes.combinedUnileverAndBrandEmailConsent
  ) {
    return getCombinedUnileverAndBrandEmailConsent(
      isEpsilonMarket,
      countryCode
    );
  } else if (consentType === consentTypes.unileverWhatsAppConsent) {
    return getUnileverWhatsAppConsent(isEpsilonMarket, countryCode);
  } else if (consentType === consentTypes.brandWhatsAppConsent) {
    return getBrandWhatsAppConsent(isEpsilonMarket, countryCode);
  } else if (consentType === consentTypes.brandSMSConsent) {
    return getBrandSMSConsent(
      isEpsilonMarket,
      countryCode,
      brandCode,
      campaignConfig
    );
  } else if (consentType === consentTypes.unileverWhatsAppOnlyConsent) {
    return getUnileverWhatsAppOnlyConsent(isEpsilonMarket, countryCode);
  } else throw new Error(`Consent Type: ${consentType} is not supported`);
};

export const getUnileverEmailConsent = (isEpsilonMarket, countryCode) => ({
  optInType: consentTypes.unileverEmailConsent,
  label: 'Unilever Email',
  serviceId: !isEpsilonMarket ? 'UNILEVER_' + countryCode + '_EML' : ''
});

export const getUnileverWhatsAppConsent = (isEpsilonMarket, countryCode) => ({
  optInType: consentTypes.unileverWhatsAppConsent,
  label: 'Unilever WhatsApp',
  serviceId: !isEpsilonMarket ? 'UNILEVER_' + countryCode + '_WHATSAPP' : ''
});

export const getUnileverWhatsAppOnlyConsent = (
  isEpsilonMarket,
  countryCode
) => ({
  optInType: consentTypes.unileverWhatsAppOnlyConsent,
  label: 'Unilever WhatsApp',
  serviceId: !isEpsilonMarket ? 'UNILEVER_' + countryCode + '_WHATSAPPONLY' : ''
});

export const getBrandWhatsAppConsent = (isEpsilonMarket, countryCode) => ({
  optInType: consentTypes.brandWhatsAppConsent,
  label: 'Brand WhatsApp',
  //serviceId: !isEpsilonMarket? 'BRAND_' + countryCode + '_' + brandCode + '_WHATSAPP': ''
  serviceId: !isEpsilonMarket ? 'BRAND_' + countryCode + '_WHATSAPP' : ''
});

export const getUnileverSMSConsent = (isEpsilonMarket, countryCode) => ({
  optInType: consentTypes.unileverSMSConsent,
  label: 'Unilever SMS',
  serviceId: !isEpsilonMarket ? 'UNILEVER_' + countryCode + '_SMS' : ''
});

export const getUnileverEmailOnlyConsent = (isEpsilonMarket, countryCode) => ({
  optInType: consentTypes.unileverEmailOnlyConsent,
  label: 'Unilever Email Only',
  serviceId: !isEpsilonMarket ? 'UNILEVER_' + countryCode + '_EMLONLY' : ''
});

export const getUnileverOnlineAdvertisingOnlyConsent = (
  isEpsilonMarket,
  countryCode
) => ({
  optInType: consentTypes.unileverOnlineOnlyConsent,
  label: 'Unilever Online Advertising',
  serviceId: !isEpsilonMarket ? 'UNILEVER_' + countryCode + '_ONLINEONLY' : ''
});

export const getUnileverSMSOnlyConsent = (isEpsilonMarket, countryCode) => ({
  optInType: consentTypes.unileverSMSOnlyConsent,
  label: 'Unilever SMS only',
  serviceId: !isEpsilonMarket ? 'UNILEVER_' + countryCode + '_SMSONLY' : ''
});

export const getMultiChannelUnileverConsent = (
  isEpsilonMarket,
  countryCode
) => ({
  optInType: consentTypes.multiChannelUnileverConsent,
  label: 'Multi-channel Unilever consent',
  serviceId: !isEpsilonMarket ? 'UNILEVER_' + countryCode + '_MULTICHANNEL' : ''
});

export const getUnileverEmailSmsOnlineAdvertising = (
  isEpsilonMarket,
  countryCode,
  campaignConfig,
  campaignType
) => {
  let whatsAppOnlyConsent = [];
  if (
    campaignConfig &&
    campaignConfig.WHATSAPP_CONSENT &&
    campaignConfig.WHATSAPP_CONSENT.UNBUNDELED_WHATSAPP_ONLY_ENABLE
  ) {
    if (
      campaignType != 'Incentive' ||
      (campaignType === 'Incentive' &&
        campaignConfig.WHATSAPP_CONSENT &&
        campaignConfig.WHATSAPP_CONSENT.INCENTIVE_WHATSAPP_ENABLE)
    ) {
      whatsAppOnlyConsent.push({
        optInType: consentTypes.unileverWhatsAppOnlyConsent,
        label: 'Unilever WhatsApp only',
        serviceId: !isEpsilonMarket
          ? 'UNILEVER_' + countryCode + '_WHATSAPPONLY'
          : ''
      });
    }
  }

  return [
    {
      optInType: consentTypes.unileverEmailOnlyConsent,
      label: 'Unilever Email Only',
      serviceId: !isEpsilonMarket ? 'UNILEVER_' + countryCode + '_EMLONLY' : ''
    },
    {
      optInType: consentTypes.unileverSMSOnlyConsent,
      label: 'Unilever SMS only',
      serviceId: !isEpsilonMarket ? 'UNILEVER_' + countryCode + '_SMSONLY' : ''
    },
    ...whatsAppOnlyConsent,
    {
      optInType: consentTypes.unileverOnlineOnlyConsent,
      label: 'Unilever Online Advertising',
      serviceId: !isEpsilonMarket
        ? 'UNILEVER_' + countryCode + '_ONLINEONLY'
        : ''
    }
  ];
};

export const getCombinedUnileverAndBrandEmailConsent = (
  isEpsilonMarket,
  countryCode
) => ({
  optInType: consentTypes.combinedUnileverAndBrandEmailConsent,
  label: 'Combined Unilever and brand email consent',
  serviceId: !isEpsilonMarket
    ? 'COMBINED_UNILEVER_' + countryCode + '_EMAIL_SMS'
    : ''
});

export const getBrandSMSConsent = (
  isEpsilonMarket,
  countryCode,
  brandCode,
  campaignConfig
) => ({
  optInType: consentTypes.brandSMSConsent,
  label:
    campaignConfig &&
    campaignConfig.US_BRAND_SMS_CONSENT_ENABLE &&
    isEpsilonMarket
      ? 'Brand SMS Consent'
      : 'Brand SMS',
  serviceId: !isEpsilonMarket
    ? 'BRAND_' + countryCode + '_' + brandCode + '_SMS'
    : ''
});

export const getBrandEmailSmsObject = (
  isEpsilonMarket,
  countryCode,
  brandCode,
  campaignConfig
) => {
  return [
    {
      optInType: 'brandEmailConsent',
      label: 'Brand Email',
      serviceId: !isEpsilonMarket
        ? 'BRAND_' + countryCode + '_' + brandCode + '_EML'
        : ''
    },
    {
      optInType: 'brandSMSConsent',
      label:
        campaignConfig &&
        campaignConfig.US_BRAND_SMS_CONSENT_ENABLE &&
        isEpsilonMarket
          ? 'Brand SMS Consent'
          : 'Brand SMS',
      serviceId: !isEpsilonMarket
        ? 'BRAND_' + countryCode + '_' + brandCode + '_SMS'
        : ''
    }
  ];
};

export const CONSENT_MESSAGE = 'consentMessage';

export const getDefaultOptInTypes = (
  campaignConfig,
  campaign,
  isUnbundledMarket
) => {
  const {
    country: { code: countrySelected },
    isEdited,
    brand: { code: brandCode },
    campaignType
  } = campaign;

  const isEpsilonMarket = !!(countrySelected && epsilonMarket(countrySelected));

  let brandEmailSmsConsent = [];
  const brandEmailSmsObject = getBrandEmailSmsObject(
    isEpsilonMarket,
    countrySelected,
    brandCode,
    campaignConfig
  );

  const IsBrandEmailsmsConsentVisible = isBrandEmailSmsConsentVisible(
    campaignConfig,
    countrySelected,
    brandCode
  );

  if (IsBrandEmailsmsConsentVisible && !isUnbundledMarket) {
    brandEmailSmsConsent = brandEmailSmsObject;
  }

  let emailSmsOnlineConsent = [];
  let brandSMSConsent = [];
  if (isUnbundledMarket) {
    emailSmsOnlineConsent = getUnileverEmailSmsOnlineAdvertising(
      isEpsilonMarket,
      countrySelected,
      campaignConfig,
      campaignType
    );
  } else {
    emailSmsOnlineConsent = [
      getUnileverSMSConsent(isEpsilonMarket, countrySelected)
    ];
    if (!isEpsilonMarket && !isUnbundledMarket) {
      emailSmsOnlineConsent.push(
        getUnileverEmailConsent(isEpsilonMarket, countrySelected)
      );
    }
  }
  let combinedUnileverAndBrandEmailConsent = [];
  if (
    isEpsilonMarket &&
    campaignConfig.COMBINED_UNILEVER_BRAND_EMAIL_CONSENT &&
    !IsBrandEmailsmsConsentVisible
  ) {
    combinedUnileverAndBrandEmailConsent = [
      getCombinedUnileverAndBrandEmailConsent(isEpsilonMarket, countrySelected)
    ];
  } else if (isEpsilonMarket) {
    emailSmsOnlineConsent.push(
      getUnileverEmailConsent(isEpsilonMarket, countrySelected)
    );
  }
  emailSmsOnlineConsent = [...new Set(emailSmsOnlineConsent)];

  let unileverEmailBrandWhatsAppConsent = [];
  if (
    !isEpsilonMarket &&
    campaignConfig.UNILEVER_AND_BRAND_WHATSAPP_CONSENT &&
    isWhatsAppConsentEnableForMarket(campaignConfig, countrySelected)
  ) {
    if (!isUnbundledMarket) {
      if (
        campaignType != 'Incentive' ||
        (campaignType === 'Incentive' &&
          campaignConfig.WHATSAPP_CONSENT &&
          campaignConfig.WHATSAPP_CONSENT.INCENTIVE_WHATSAPP_ENABLE)
      ) {
        unileverEmailBrandWhatsAppConsent.push(
          getUnileverWhatsAppConsent(isEpsilonMarket, countrySelected)
        );
      }
    }
    /* as per this bug WTG-14442  hide below field 
    if(IsBrandEmailsmsConsentVisible){
      unileverEmailBrandWhatsAppConsent.push(getBrandWhatsAppConsent(isEpsilonMarket, countrySelected))
    }*/
  }
  const multiChannelConsent = [];
  if (
    !isEpsilonMarket &&
    !isUnbundledMarket &&
    campaignConfig.MULTI_CHANNEL_CONSENT_ENABLE
  ) {
    multiChannelConsent.push(
      getMultiChannelUnileverConsent(isEpsilonMarket, countrySelected)
    );
  }

  if (
    isEpsilonMarket &&
    !IsBrandEmailsmsConsentVisible &&
    campaignConfig.US_BRAND_SMS_CONSENT_ENABLE
  ) {
    brandSMSConsent.push(
      getBrandSMSConsent(
        isEpsilonMarket,
        countrySelected,
        brandCode,
        campaignConfig
      )
    );
  }

  let optInType = [
    {
      optInType: 'legalAgeConsent',
      label: 'Age Consent',
      ageOfConsent: isEpsilonMarket ? '13' : '16'
    },
    {
      optInType: 'privacyNotice',
      label: 'Privacy Policy'
    },
    ...brandEmailSmsConsent,
    ...emailSmsOnlineConsent,
    ...combinedUnileverAndBrandEmailConsent,
    ...brandSMSConsent,
    ...unileverEmailBrandWhatsAppConsent,
    ...multiChannelConsent,
    {
      optInType: consentTypes.additionalSubscription,
      label: 'Additional Opt-ins (Regular Comms.) 1'
    },
    {
      optInType: consentTypes.additionalSubscription1,
      label: 'Additional Opt-ins (Regular Comms.) 2'
    },
    { optInType: 'termsAndConditions', label: 'Terms and Conditions' },
    { optInType: 'legalDisclaimer', label: 'Disclaimer' }
  ];

  if (!campaignConfig.showAdditionalOptins) {
    optInType = optInType.filter(
      elem =>
        elem.optInType !== consentTypes.additionalSubscription &&
        elem.optInType !== consentTypes.additionalSubscription1
    );
  }

  if (isEpsilonMarket && campaignConfig.US_BRAND_SMS_CONSENT_ENABLE) {
    optInType = optInType.filter(
      elem =>
        elem.optInType !== consentTypes.unileverSMSConsent &&
        // elem.optInType !== consentTypes.brandSMSConsent &&
        elem.optInType !== consentTypes.additionalSubscription &&
        elem.optInType !== consentTypes.additionalSubscription1
    );
  } else if (isEpsilonMarket) {
    optInType = optInType.filter(
      elem =>
        elem.optInType !== consentTypes.unileverSMSConsent &&
        elem.optInType !== consentTypes.brandSMSConsent &&
        elem.optInType !== consentTypes.additionalSubscription &&
        elem.optInType !== consentTypes.additionalSubscription1
    );
  }
  if (checkIsCIAEnabled(campaign)) {
    optInType.splice(
      !isEpsilonMarket &&
        !isUnbundledMarket &&
        campaignConfig.MULTI_CHANNEL_CONSENT_ENABLE
        ? 8
        : 6,
      0,
      {
        optInType: 'contentInterestAreas',
        label: 'Content Interest Areas'
      }
    );
  }
  if (isEdited) {
    optInType = optInType.filter(
      elem =>
        elem.optInType !== consentTypes.additionalSubscription &&
        elem.optInType !== consentTypes.additionalSubscription1
    );
  }
  optInType.forEach(elem => (elem.type = CONSENT_MESSAGE));
  return optInType;
};

export const getConsentVersion = campaign => {
  const {
    country: { code: countrySelected }
  } = campaign;

  const isEpsilonMarket = !!(countrySelected && epsilonMarket(countrySelected));

  return constant.featureFlags.ENABLE_ENTERPRISE_CONSENT
    ? 'enterpriseConsentsVersion3'
    : campaign.campaignDetail.consentsVersion ||
        (isEpsilonMarket ? 'enterpriseConsentsVersion3' : '');
};

export const getConsentData = consentMessage => {
  const ageConsentData = consentMessage
    ? consentMessage.find(elem => elem.dataType === 'legalAgeConsent')
    : {};
  const brandEmailConsentData = consentMessage
    ? consentMessage.find(elem => elem.dataType === 'brandEmailConsent')
    : {};
  const brandSMSConsentData = consentMessage
    ? consentMessage.find(elem => elem.dataType === 'brandSMSConsent')
    : {};
  const unileverEmailConsentData = consentMessage
    ? consentMessage.find(
        elem => elem.dataType === consentTypes.unileverEmailConsent
      )
    : {};
  const unileverSMSConsentData = consentMessage
    ? consentMessage.find(
        elem => elem.dataType === consentTypes.unileverSMSConsent
      )
    : {};
  const termsAndConditionsData = consentMessage
    ? consentMessage.find(elem => elem.dataType === 'termsAndConditions')
    : {};

  const unileverEmailOnlyConsentData = consentMessage
    ? consentMessage.find(elem => elem.dataType === 'unileverEmailOnlyConsent')
    : {};

  const unileverSMSOnlyConsentData = consentMessage
    ? consentMessage.find(elem => elem.dataType === 'unileverSMSOnlyConsent')
    : {};

  const unileverOnlineOnlyConsentData = consentMessage
    ? consentMessage.find(elem => elem.dataType === 'unileverOnlineOnlyConsent')
    : {};

  const unileverWhatsAppConsentData = consentMessage
    ? consentMessage.find(elem => elem.dataType === 'unileverWhatsAppConsent')
    : {};

  const brandWhatsAppConsentData = consentMessage
    ? consentMessage.find(elem => elem.dataType === 'brandWhatsAppConsent')
    : {};

  const unileverWhatsApponlyConsentData = consentMessage
    ? consentMessage.find(
        elem => elem.dataType === 'unileverWhatsAppOnlyConsent'
      )
    : {};

  const multiChannelUnileverConsentData = consentMessage
    ? consentMessage.find(
        elem => elem.dataType === 'multiChannelUnileverConsent'
      )
    : {};
  return {
    ageConsentData,
    brandEmailConsentData,
    brandSMSConsentData,
    unileverEmailConsentData,
    unileverSMSConsentData,
    termsAndConditionsData,
    unileverEmailOnlyConsentData,
    unileverSMSOnlyConsentData,
    unileverOnlineOnlyConsentData,
    unileverWhatsAppConsentData,
    brandWhatsAppConsentData,
    unileverWhatsApponlyConsentData,
    multiChannelUnileverConsentData
  };
};

export const getLegalAge = campaign => {
  return epsilonMarket(campaign && campaign.country && campaign.country.code)
    ? '13'
    : '16';
};

export const getAgeOfConsent = campaign => {
  return (
    campaign && campaign.campaignDetail && campaign.campaignDetail.ageOfConsent
  );
};

export const isConsentUpdateNeeded = (campaign, isEpsilonMarket) => {
  const isOlderConsentPresent =
    campaign.campaignDetail.consentsVersion !== 'enterpriseConsentsVersion3';
  return (
    (constant.featureFlags.ENABLE_ENTERPRISE_CONSENT &&
      (!campaign.campaignDetail.consentsVersion || isOlderConsentPresent)) ||
    (!campaign.campaignDetail.consentsVersion && isEpsilonMarket)
  );
};

export const isOlderConsentBeingUpdated = campaign => {
  const isOlderConsentPresent =
    campaign.campaignDetail.consentsVersion &&
    campaign.campaignDetail.consentsVersion !== 'enterpriseConsentsVersion3';
  return (
    constant.featureFlags.ENABLE_ENTERPRISE_CONSENT && isOlderConsentPresent
  );
};

export const isConsentVersionUpdateRequired = campaign => {
  const isOlderConsentPresent =
    !campaign.campaignDetail.consentsVersion ||
    campaign.campaignDetail.consentsVersion !== 'enterpriseConsentsVersion3';
  return (
    constant.featureFlags.ENABLE_ENTERPRISE_CONSENT && isOlderConsentPresent
  );
};

export const isSomeConsentAvailable = consentsForSelectedVersion => {
  if (consentsForSelectedVersion && consentsForSelectedVersion.length) {
    return Object.entries(consentsForSelectedVersion[0]).some(
      ([key, value]) => {
        return (
          value && constant.consents.some(consent => consent.tsKey === key)
        );
      }
    );
  } else return false;
};

export const insertDefaultConsentsIfNotAvailable = consentsForSelectedVersion => {
  if (
    !(consentsForSelectedVersion && consentsForSelectedVersion[0].age_consent)
  ) {
    if (consentsForSelectedVersion) {
      consentsForSelectedVersion[0].age_consent =
        constant.defaultConsents.age_consent;
    }
  }
  if (
    !(
      consentsForSelectedVersion && consentsForSelectedVersion[0].privacy_policy
    )
  ) {
    if (consentsForSelectedVersion) {
      consentsForSelectedVersion[0].age_consent =
        constant.defaultConsents.privacy_policy;
    }
  }
  return consentsForSelectedVersion;
};

export function getLoggedInUser(msalInstance) {
  if (
    process.env.NODE_ENV === 'development' ||
    process.env.NODE_ENV === 'local'
  ) {
    return {
      username: process.env.REACT_APP_USER_EMAIL || 'test@unilever.com',
      name: process.env.REACT_APP_USER_NAME || 'John Doe'
    };
  }
  return msalInstance.getActiveAccount();
}

export function getMobilePreviewDates(data) {
  let currentDateTime = moment.utc();
  let dates = {
    broadcastDate1: currentDateTime.format('DD/MM/YYYY'),
    broadcastTime1: currentDateTime.format('h:mm A'),
    broadcastDate2: currentDateTime.add(2, 'days').format('DD/MM/YYYY'),
    broadcastTime2: currentDateTime.add(2, 'days').format('h:mm A')
  };
  const deliveryType = get(data, 'deliverySchedule.smsTrigger.type');
  const isFollowUp =
    get(data, 'deliverySchedule.sendAfter.value') !== 'DontSend' ||
    get(data, 'status') === '2';
  if (deliveryType === 'fixedDateAndtime') {
    const date = get(
      data,
      'deliverySchedule.smsTrigger.details.smsDeliveryDate',
      new Date()
    );
    const time = get(
      data,
      'deliverySchedule.smsTrigger.details.smsDeliveryTime',
      new Date()
    );
    dates.broadcastDate1 = moment.utc(date).format('DD/MM/YYYY');
    dates.broadcastTime1 = moment.utc(time).format('h:mm A');
    if (isFollowUp) {
      const daysToAdd = parseInt(
        get(data, 'deliverySchedule.sendAfter.value', '2')
      );
      dates.broadcastDate2 = moment
        .utc(date)
        .add(daysToAdd, 'days')
        .format('DD/MM/YYYY');
      dates.broadcastTime2 = moment
        .utc(time)
        .add(daysToAdd, 'days')
        .format('h:mm A');
    }
  }
  if (deliveryType === 'dynamicTrigger') {
    const date = get(
      data,
      'deliverySchedule.smsTrigger.details.triggerConditionStartDate',
      new Date()
    );
    const time = get(
      data,
      'deliverySchedule.smsTrigger.details.triggerConditionStartDate',
      new Date()
    );
    dates.broadcastDate1 = moment.utc(date).format('DD/MM/YYYY');
    dates.broadcastTime1 = moment.utc(time).format('h:mm A');
    if (isFollowUp) {
      const daysToAdd = parseInt(
        get(data, 'deliverySchedule.sendAfter.value', '2')
      );
      dates.broadcastDate2 = moment
        .utc(date)
        .add(daysToAdd, 'days')
        .format('DD/MM/YYYY');
      dates.broadcastTime2 = moment
        .utc(time)
        .add(daysToAdd, 'days')
        .format('h:mm A');
    }
  }
  return dates;
}

export function isGSMAlphabet(char) {
  const regexp = new RegExp(constant.GSM_AlPHABETS_REGEX);
  return regexp.test(char);
}

export function isExtendedGSM(char) {
  const extendedGSM = constant.EXTENDED_GSM_CHARACTERS;
  return extendedGSM.includes(char);
}

const smsDynamicContentList = [
  {
    exp: /FName/g,
    text: '[FName]'
  },
  {
    exp: /Coupon/g,
    text: '[Coupon]'
  },
  {
    exp: new RegExp(`${constant.SAMPLE_BITLY_URL}`, 'g'),
    text: `[${constant.SAMPLE_BITLY_URL}]`
  }
];

export function getGSMandUnicodeStats(content, shortURL) {
  if (typeof content === 'string') {
    // defaults with gsm content stats
    const stats = {
      limit: constant.SMS_GSM_CHARACTERS_LIMIT,
      segmentLimit: constant.SMS_GSM_SEGMENT_LIMIT,
      hasUnicode: false,
      numCharacters: 0,
      segments: 1
    };
    let msgLength = 0;
    for (var i = 0; i < content.length; i++) {
      const thisChar = content.charAt(i);
      const isGSMChar = isGSMAlphabet(thisChar);
      const isExtendedGSMChar = isExtendedGSM(thisChar);
      const charSet = isExtendedGSMChar
        ? 'extended'
        : isGSMChar
        ? 'gsm'
        : 'unicode';
      const charLength = charSet === 'gsm' ? 1 : charSet === 'unicode' ? 1 : 2;
      msgLength = msgLength + charLength;
      stats.numCharacters = msgLength;
      if (charSet === 'unicode') {
        stats.hasUnicode = true;
        stats.limit = constant.SMS_UNICODE_CHARACTER_LIMIT;
        stats.segmentLimit = constant.SMS_UNICODE_SEGMENT_LIMIT;
      }
    }

    // adjust no of chars for dynamic placeholder
    let adjustmentValue = 0;

    let dynamicContentList = [...smsDynamicContentList];

    if (shortURL && shortURL !== constant.SAMPLE_BITLY_URL) {
      dynamicContentList.push({
        exp: new RegExp(`${shortURL}`, 'g'),
        text: `[${shortURL}]`
      });
    }

    dynamicContentList.forEach(item => {
      let matchList = content.match(item.exp) || [];
      if (matchList.length) {
        for (let j = 1; j <= matchList.length; j++) {
          adjustmentValue += 4;
        }
      }
    });

    stats.numCharacters = stats.numCharacters - adjustmentValue;

    if (stats.numCharacters > stats.limit) {
      stats.segments =
        stats.numCharacters > stats.limit
          ? Math.ceil(stats.numCharacters / stats.segmentLimit)
          : 1;
    }

    return stats;
  }
  return null;
}

export function getCharCountText(contentStats) {
  return contentStats.segments > 1
    ? `${contentStats.numCharacters}/${contentStats.segmentLimit} (${contentStats.segments} SMS parts)`
    : `${contentStats.numCharacters}/${contentStats.limit}`;
}

export function transformWADataFromAPI(data) {
  const formattedData = cloneDeep(data);

  if (data.brand) {
    formattedData.brand = {
      label: data.brand.name,
      value: data.brand.code,
      id: data.brand._id
    };
  }

  if (data.country) {
    formattedData.country = {
      label: data.country.name,
      value: data.country.code,
      id: data.country._id
    };
  }

  if (data.language) {
    formattedData.language = {
      label: data.language.name,
      value: data.language.code,
      id: data.language._id
    };
  }

  return formattedData;
}

export function getWAButtonDefaults(buttonList, waCouponType, waCouponCode) {
  return buttonList && buttonList.length
    ? buttonList.map(item => {
        if (item?.type?.value === 'Copy coupon code') {
          if (
            waCouponType === 'staticCoupon' &&
            item.buttonValue !== waCouponCode
          ) {
            return {
              ...item,
              buttonValue: waCouponCode
            };
          }
        }
        return item;
      })
    : buttonList;
}
