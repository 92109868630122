import React, { useState } from 'react';
export const AddButtonMenu = ({
  getValues,
  setValue,
  waCouponType,
  waCouponCode,
  props
}) => {
  const maxNumberOfButtonsAllowed = 10;
  const ButtonTypeOptions = [
    { value: 'Website URL', label: 'Website URL (max 2)' },
    {
      value: 'Phone number',
      label: 'Phone number (max 1)'
    },
    {
      value: 'Unsubscribe quick reply',
      label: 'Unsubscribe quick reply (max 1)'
    },
    {
      value: 'Copy coupon code',
      label: 'Copy coupon code (max 1)'
    }
  ];
  // if (props.waCouponType) {
  //   ButtonTypeOptions.push();
  // }

  const [buttonOptions, setbuttonOptions] = useState(ButtonTypeOptions);
  const [isAddbuttonOptionsOpen, setIsAddbuttonOptionsOpen] = useState(false);

  const addQuickReplyRow = evt => {
    const currentVal = getValues();
    const indexOfQuickReply = findIndexById(currentVal, 'quickReply');
    const elementType = evt?.target?.id;
    const elementMaxVal = evt?.target?.dataset.max;
    const { elementTypeCount, totalNestedArrayCount } = countObjectsByType(
      currentVal.waButtons,
      elementType
    );
    // if current count is less than max allowed limit
    if (
      indexOfQuickReply >= 0 &&
      elementTypeCount < elementMaxVal &&
      totalNestedArrayCount < maxNumberOfButtonsAllowed
    ) {
      const oldState = currentVal.waButtons[indexOfQuickReply].nestedArray;
      setValue(`waButtons.${indexOfQuickReply}.nestedArray`, [
        ...oldState,
        {
          type: elementType,
          buttonName: '',
          buttonValue: ''
        }
      ]);
    }
  };

  const addNonQuickReplyRow = evt => {
    const currentVal = getValues();
    const indexOfNonQuickReply = findIndexById(currentVal, 'nonQuickReply');
    const elementType = evt?.target?.id;
    const elementMaxVal = evt?.target?.dataset.max;
    const { elementTypeCount, totalNestedArrayCount } = countObjectsByType(
      currentVal.waButtons,
      elementType
    );
    // if current count is less than max allowed limit
    if (
      indexOfNonQuickReply >= 0 &&
      elementTypeCount < elementMaxVal &&
      totalNestedArrayCount < maxNumberOfButtonsAllowed
    ) {
      const oldState = currentVal.waButtons[indexOfNonQuickReply].nestedArray;
      const buttonDefaultVal =
        elementType === 'Copy code button' && waCouponType === 'staticCoupon'
          ? waCouponCode
          : '';
      const buttonDefaultName =
        elementType === 'Copy code button' ? 'Coupon code' : '';
      setValue(`waButtons.${indexOfNonQuickReply}.nestedArray`, [
        ...oldState,
        {
          type: elementType,
          buttonName: buttonDefaultName,
          buttonValue: buttonDefaultVal
        }
      ]);
    } else {
      // TODO: disable css current option
    }
  };

  function findIndexById(currentVal, id) {
    return currentVal.waButtons.findIndex(button => button?.id === id);
  }
  /**
   * function to count the occurrences of objects.
   * For example count "type" = "copyCode" within the "nestedArray" key
   * @param {*} sourceArray
   * @param {*} elementType
   * @returns
   */
  const countObjectsByType = (sourceArray, elementType) => {
    let elementTypeCount = 0;
    let totalNestedArrayCount = 0;
    sourceArray.forEach(item => {
      totalNestedArrayCount += item.nestedArray.length;
      elementTypeCount += item.nestedArray.filter(
        nestedItem => nestedItem.type === elementType
      ).length;
    });
    return {
      elementTypeCount,
      totalNestedArrayCount
    };
  };

  return (
    <>
      <div className="btn-group dropup mt-30 mb-10">
        <button
          onClick={() => setIsAddbuttonOptionsOpen(!isAddbuttonOptionsOpen)}
          type="button"
          className="btn btn-outline-secondary dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Add button
        </button>
        <div
          className={
            isAddbuttonOptionsOpen ? 'dropdown-menu show' : 'dropdown-menu'
          }
        >
          <a
            className="dropdown-item"
            onClick={e => {
              e.preventDefault();
              setIsAddbuttonOptionsOpen(!isAddbuttonOptionsOpen);
              addNonQuickReplyRow(e);
            }}
            id="Phone number button"
            data-max={1}
            href="#"
          >
            Phone number button{' '}
            <span className="email-label-subtext">(max 1)</span>
          </a>
          <a
            className="dropdown-item"
            onClick={e => {
              e.preventDefault();
              setIsAddbuttonOptionsOpen(!isAddbuttonOptionsOpen);
              addNonQuickReplyRow(e);
            }}
            id="Website URL"
            data-max={2}
            href="#"
          >
            Website URL <span className="email-label-subtext">(max 2)</span>
          </a>
          <a
            className="dropdown-item"
            onClick={e => {
              e.preventDefault();
              setIsAddbuttonOptionsOpen(!isAddbuttonOptionsOpen);
              addNonQuickReplyRow(e);
            }}
            id="Copy code button"
            data-max={1}
            href="#"
          >
            Copy code button{' '}
            <span className="email-label-subtext">(max 1)</span>
          </a>
          <div className="dropdown-divider"></div>
          <a
            className="dropdown-item"
            onClick={e => {
              e.preventDefault();
              setIsAddbuttonOptionsOpen(!isAddbuttonOptionsOpen);
              addQuickReplyRow(e);
            }}
            id="Quick reply - Unsubscribe"
            data-max={1}
            href="#"
          >
            Quick reply - Unsubscribe{' '}
            <span className="email-label-subtext">(max 1)</span>
          </a>
          <a
            className="dropdown-item"
            onClick={e => {
              e.preventDefault();
              setIsAddbuttonOptionsOpen(!isAddbuttonOptionsOpen);
              addQuickReplyRow(e);
            }}
            id="Quick reply - Others"
            data-max={10}
            href="#"
          >
            Quick reply - Others{' '}
            <span className="email-label-subtext">(max 10)</span>
          </a>
        </div>
      </div>
    </>
  );
};
